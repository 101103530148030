// Should you need to overwrite any of our SCSS code or need to add any custom code, We highly recommend that you add your code into this file, so whenever theme update is available and you update the theme, You will not lose your hard work :)

/*User CSS*/// Special logos in the footer

  .instagram {
    margin-top:10px;
  }
  .facebook {
    margin-top: -10px;
    margin-left: -10px;
  }
  .facebook-logo {
    height: 38px;
  }
  .instagram-logo {
    height: 24px;
    width: 85px;
  }

  .line-separator {
    color: red;
    text-align: center;
    width: 170px;
    margin: 0px auto 1em;
  
    &:after {
      display: block;
      box-sizing: border-box;
      content: ' ' ' ';
      float: right;
      width: 63px;
      border: 2px solid red;
      margin: 8px 0px 0px;
    }
  
    &:before {
      display: block;
      box-sizing: border-box;
      content: ' ' ' ';
      float: left;
      width: 63px;
      border: 2px solid red;
      margin: 8px 0px 0px;
    }
  }
  
.grecaptcha-badge { 
    visibility: hidden;
}