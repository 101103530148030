// 
// buttons.scss
// Extended from Bootstrap
// 

//
// Bootstrap Overrides
//

.btn-link{
  padding-bottom: 0px;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%);
  background-size: 0px 6%;
  background-repeat: no-repeat;
  background-position: left 100%;
  transition-duration: .5s;
  font-weight: inherit;
  padding: 0;
}
.btn-link:hover {
  background-size: 100% 6%;
}


// 
// Additional styles for theme
// 

.btn {
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden;
  outline: 0;
  white-space: nowrap;
  margin-bottom: 6px;
}
.input-group .btn{
  margin-bottom: 0;
}
.btn-xs{
  padding: 0.4rem 0.6rem;
  font-size: 0.6rem;
  line-height: 1.2;
  border-radius: $btn-border-radius;
}

// button white
.btn-white {
  background: $white;
  color: $gray-800;
  &.active, &:active, &:focus, &:hover {
    background: $gray-200;
    color: $gray-800;
  }
}
.btn-outline-white {
  background: transparent;
  border-color: $white;
  color: $white;
  &.active, &:active, &:focus, &:hover {
    color: $gray-800;
    background: $white;
  }
}
.btn-outline-light{
  background: transparent;
  border-color: $gray-400;
  color: $body-color;
}
.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-check:checked + .btn-outline-light
{
  background: $gray-400;
  border-color: $gray-400;
  color: $gray-800;
}

// button round
.btn-round{
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  i{
    margin:0; 
  }
  // Quick fix for play button centered
  .fa-play{
    padding-left: 3px;
  }
  &.btn-lg{
    height: 55px;
    width: 55px;
    line-height: 55px;
  }
  &.btn-sm{
    height: 32px;
    width: 32px;
    line-height: 30px;
    i{
      font-size: .6rem;
    }
  }
}

// Quick fix for play button centered in RTL
/*rtl:raw:
.btn-round .fa-play{
  padding-left: 3px;
  padding-right: 0;
}
*/

// button soft
// @mixin button-soft-variant($color, $soft-background: rgba($color, .1)) {
//   color: $color;
//   background-color: $soft-background;
//   &:hover {
//     color: color-contrast($color);
//     background-color: $color;
//   }
// }
// @each $color, $value in $theme-colors {
//   .btn-#{$color}-soft {
//     @include button-soft-variant($value);
//   }
// }

@mixin button-soft-variant($color, $soft-background: rgba($color, .1)) {
  color: $color;
  background-color: $soft-background;
  &:active, &:hover {
    color: color-contrast($color);
    background-color: $color;
    border-color: $color; 
  }
  &:focus {
    color: $color;
    background-color: $soft-background;
    border-color: transparent; 
  }
}
@each $color, $value in $theme-colors {
  .btn-#{$color}-soft {
    @include button-soft-variant($value);
  }
}

// Advance filter see more
.btn-more i{
  transition: $transition-base;
}
.btn-more[aria-expanded="true"] i{
  transform: rotate(180deg);
  transition: $transition-base;
  transform-origin: center;

}
.btn-more .see-more{
  display:block;
}
.btn-more .see-less{
  display:none;
}
.btn-more[aria-expanded="true"] .see-more{
  display:none;
}
.btn-more[aria-expanded="true"] .see-less{
  display:block;
}