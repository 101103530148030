// 
// pagination.scss
// Extended from Bootstrap
// 

// 
// Additional style for theme
//

// For better responsive
.pagination {
  .page-item{
    margin-bottom: 5px;
    float: left;
  }
}

// pagination bordered
.pagination-bordered{
  border: 1px solid $border-color;
  padding: 4px;
  border-radius: $border-radius;
  .page-item{
    margin: 2px;
  }
  .page-link{
    border: transparent;
    border-radius: $border-radius;
  }
}
