// 
// utilities.scss
// Theme component for utility classes (helper classes)


// text color on hover
.text-primary-hover {
  a {
    color: $gray-400;
    &:hover {
      color: $primary !important;
    }
  }
}

a.text-primary-hover, button.text-primary-hover {
  color: $gray-400;
  &:hover {
    color: $primary !important;
  }
}

.text-primary-hover {
  li {
    color: $gray-400;
    &:hover {
      color: $primary !important;
    }
  }
}

// font family
.font-base {
  font-family: $font-family-base !important;
}

.font-heading {
  font-family: $font-family-heading !important;
}

// Filter
.grayscale {
  filter: grayscale(100%);
  transition: all .6s ease;
  &:hover {
    filter: none;
    -webkit-filter: grayscale(0%);
  }
}

// On hover
.zoom-hover {
  transform: scale(1);
  &:hover {
    transform: scale(1.2);
  }
}

.up-hover {
  transition: $transition-base;
  &:hover {
    transform: translateY(-10px);
  }
}

// Icons fixed height and width
.icon-sm {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  font-size: 75%;
}
.icon-md {
  width: 2.8rem;
  height: 2.8rem;
  line-height: 2.8rem;
  text-align: center;
  font-size: .8rem;
}
.icon-lg {
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  font-size: 1.2rem;
}
.icon-xl {
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
  font-size: 1.9rem;
}

// oversized block for force outside of col width
@include media-breakpoint-up(lg) {
  .oversized-block{
    width: calc(100% + 140px - 20px + 140px - 20px);
    margin-left: calc(-140px + 20px);
  }
}
