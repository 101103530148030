// 
// nav.scss
// Extended from Bootstrap
// 

// 
// Additional style for theme
// 

// Pills
.nav-pills {
  .nav-link {
    cursor: pointer;
    margin-bottom: 15px;
    padding: 0.5rem 1rem;
  }
}

// nav
.nav.flex-column:not(.nav-pills) .nav-link {
  padding-left: 0;
  padding-top: 0.30rem;
  padding-bottom: 0.30rem;
}

// Tab
.tab-content {
  padding: 25px 0;
  margin-bottom: 20px;
}

.nav-tabs {
  .nav-link {
    padding: 0.5rem 1rem;
    border: none;
  }
  .nav-link.active {
    background: $primary;
    color: $white;
    border: none;
    .nav-link {
      color: $white;
    }
  }
}

// tab line
.nav-tabs.nav-tabs-line { 
  border-bottom: solid 3px $border-color;
  .nav-link {
    border: transparent;
  }
  .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .nav-link {
    background: transparent;
    border-bottom: 3px solid transparent;
    &.active {
      background: transparent;
      color: $primary;
      border-bottom: 3px solid $primary;
    }
  }
  .nav-item {
    margin-bottom: -2px;
  }
}

// nav tabs dark
.nav-tabs.nav-tabs-dark {
  .nav-item .nav-link {
    color: $gray-800;
  }
  .nav-link.active {
    background: $gray-800;
    color: $white;
  }
}

// nav divider
.nav.nav-divider{
  a:not(.dropdown-item){
    color: inherit;
  }
  .nav-item+.nav-item:before{
    content: "\2022";
    color: inherit;
    padding-left: .65rem;
    padding-right: .75rem;
    opacity: 0.8;
  }
  .nav-item{
    display: inline-block;
    vertical-align: middle;
  }
  .nav-link{
    padding: 0;
    color: inherit;
  }
}

//Nav pill dark soft
.nav-pill-dark-soft .nav-link {
  background-color: rgba($dark-gray, 0.1);
  color: $dark-gray;
  border: none;
}
.nav-pill-dark-soft .nav-link.active, .nav-pill-dark-soft .show > .nav-link {
  color: $white;
  background-color: $dark-gray;
}